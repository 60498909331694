import React from "react";
import "./player.css";

export default class Dealer extends React.Component {
  render() {
    return (
      <div className="dealer">
        <button type="submit">
          Mint 1<br />
          100 xtz
        </button>
        <button type="submit">
          Mint 3<br />
          50 xtz
        </button>
        <button type="submit">
          Mint 5<br />
          10 xtz
        </button>
      </div>
    );
  }
}

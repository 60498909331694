import "./card.css";

export default function Card(props) {
  return (
    <div className="card">
      <h4>{props.title}</h4>
      <div className="separator" />
      <p>{props.description ? props.description : props.children}</p>
    </div>
  );
}

import "./card.css";
import Card from "./Card";

export default function ExplanationCardsContainer(props) {
  return (
    <div className="cards-container">
      <Card title="Collect">
        Get your Trackz directly from{" "}
        <a href="https://www.fxhash.xyz/u/R.Y.D">FXHash</a> and choose between
        our different collections.
      </Card>

      <Card
        title="Sync"
        description="Sync your wallet and retrieve the Tracks that composed your song."
      />

      <Card
        title="Mix"
        description="Play with your Trackz and make your music evolve. Introduce yourself to simple rhythmic and melodic concepts and try to find the best sequences."
      />
    </div>
  );
}

import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";

class TezosService {
  private tezos: TezosToolkit;
  private wallet: BeaconWallet;

  constructor() {
    this.tezos = new TezosToolkit("https://mainnet-tezos.giganode.io");
    this.wallet = new BeaconWallet({ name: "Test Wallet" });

    this.tezos.setWalletProvider(this.wallet);
  }

  async getActiveAccount() {
    return await this.wallet.client.getActiveAccount();
  }

  async isSync() {
    return Boolean(await this.getActiveAccount());
  }

  async connect() {
    try {
      console.log("Requesting permissions...");
      const permissions = await this.wallet.client.requestPermissions();
      console.log("Got permissions:", permissions.address);
    } catch (error) {
      console.error("Error while requesting wallet permission:", error);
    }
  }

  async disconnect() {
    await this.wallet.clearActiveAccount();
  }
}

export default new TezosService();

import * as Tone from "tone";
import trackSet from "../../config/current-track-set";

class SchedulerService {
  private scheduledEvents: Array<() => void> = [];
  private schedulerEventId;
  private synth: Tone.DuoSynth;
  private metronomeIsActive = false;

  get isPlaying() {
    return Tone.Transport.state === "started";
  }

  get isMetronomeActive() {
    console.log(`Metronome is active = ${this.metronomeIsActive}`);
    return this.metronomeIsActive;
  }

  constructor() {
    this.synth = new Tone.DuoSynth().toDestination();
    this.synth.volume.value = -15;
    console.log("Scheduler service created.");
    Tone.Transport.bpm.value = trackSet.bpm;
    Tone.Transport.timeSignature = 4;
  }

  toggleMetronome = () => {
    this.metronomeIsActive = !this.metronomeIsActive;
  };

  registerEvent = (event: () => void) => {
    this.scheduledEvents.push(event);

    if (!this.isPlaying) {
      this.start();
    }
  };

  private start() {
    // Tone.start();
    this.schedulerEventId = Tone.Transport.scheduleRepeat((time) => {
      this.executeScheduledEvents();

      if (this.isMetronomeActive) {
        this.synth.triggerAttackRelease("C3", 0.01, time);
      }
    }, "1m");
    Tone.Transport.start();
  }

  stop() {
    Tone.Transport.clear(this.schedulerEventId);
    this.scheduledEvents = [];
    delete this.schedulerEventId;
    Tone.Transport.stop();
  }

  private executeScheduledEvents(): void {
    while (this.scheduledEvents.length > 0) {
      this.scheduledEvents.pop()();
    }
  }
}

export default new SchedulerService();

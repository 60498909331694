import React from "react";
import Trackz from "./Trackz";
import scheduler from "../../services/scheduler";
import "./player.css";
import trackSet from "../../../config/current-track-set";

export default class TrackzList extends React.Component {
  currentPlayingTrackz: string[] = [];

  onTrackzStart = (trackzId: string) => {
    this.currentPlayingTrackz.push(trackzId);
  };

  onTrackzStop = (trackzId: string) => {
    this.currentPlayingTrackz.filter((t) => t != trackzId);

    if (this.currentPlayingTrackz.length == 0) {
      scheduler.stop();
    }
  };

  render() {
    return (
      <div className="trackz-list">
        <label>
          <input type="checkbox" onChange={scheduler.toggleMetronome} />{" "}
          Metronome
        </label>
        <ul>
          {trackSet.paths.map((path) => {
            const trackzProps = {
              trackPath: path,
              onStart: this.onTrackzStart,
              onStop: this.onTrackzStop,
            };
            return (
              <li key={path}>
                <Trackz {...trackzProps} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

import React from "react";
import "./header.css";
import "../../../main.css";
import tezos from "../../services/tezos";

export default class Header extends React.Component {
  state = {
    isWalletSync: false,
  };

  constructor(props) {
    super(props);

    tezos.isSync().then((activeAccount) => {
      this.setState({ isWalletSync: activeAccount });
    });
  }

  onConnectButtonClicked = async () => {
    console.log("Clicked");
    if (await tezos.isSync()) {
      this.disconnect();
    } else {
      this.connect();
    }
  };

  connect = async () => {
    console.log("Connect");
    await tezos.connect();
    this.setState({ isWalletSync: await tezos.isSync() });
  };

  disconnect = async () => {
    console.log("Disconnect");
    await tezos.disconnect();
    this.setState({ isWalletSync: await tezos.isSync() });
  };

  render() {
    return (
      <section className="header">
        <div className="title-container">
          <h1>
            TRACK<span className="white-z">Z</span>
          </h1>
          <svg
            className="logo"
            width="152"
            height="95"
            viewBox="0 0 152 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.348731"
              y="2.08604"
              width="69.0488"
              height="69.0488"
              stroke="white"
              strokeWidth="0.697463"
            />
            <circle
              cx="69.2903"
              cy="35.7634"
              r="35.484"
              fill="#2C333C"
              stroke="white"
              strokeWidth="0.558804"
            />
            <path
              d="M65.8936 71.0908L105.531 2.43608L145.169 71.0908H65.8936Z"
              fill="#2C333C"
              stroke="white"
              strokeWidth="0.698775"
            />
          </svg>
        </div>
        <menu className="navigation-menu">
          <span className="menu-item">FAQ</span>
          <span className="menu-item">About us</span>
        </menu>
        <menu className="socials">
          <a className="social" href="">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.744 18.8001C17.984 19.1041 18.272 19.4481 18.272 19.4481C20.04 19.3921 20.72 18.2321 20.72 18.2321C20.72 15.6561 19.568 13.5681 19.568 13.5681C18.416 12.7041 17.32 12.7281 17.32 12.7281L17.208 12.8561C18.568 13.2721 19.2 13.8721 19.2 13.8721C18.368 13.4161 17.552 13.1921 16.792 13.1041C16.216 13.0401 15.664 13.0561 15.176 13.1201C15.1341 13.1201 15.0983 13.1262 15.0579 13.1331L15.04 13.1361C14.76 13.1601 14.08 13.2641 13.224 13.6401C12.928 13.7761 12.752 13.8721 12.752 13.8721C12.752 13.8721 13.416 13.2401 14.856 12.8241L14.776 12.7281C14.776 12.7281 13.68 12.7041 12.528 13.5681C12.528 13.5681 11.376 15.6561 11.376 18.2321C11.376 18.2321 12.048 19.3921 13.816 19.4481C13.816 19.4481 14.112 19.0881 14.352 18.7841C13.336 18.4801 12.952 17.8401 12.952 17.8401C12.952 17.8401 13.032 17.8961 13.176 17.9761C13.184 17.9841 13.192 17.9921 13.208 18.0001C13.22 18.0081 13.232 18.0141 13.244 18.0201C13.256 18.0261 13.268 18.0321 13.28 18.0401C13.48 18.1521 13.68 18.2401 13.864 18.3121C14.192 18.4401 14.584 18.5681 15.04 18.6561C15.64 18.7681 16.344 18.8081 17.112 18.6641C17.488 18.6001 17.872 18.4881 18.272 18.3201C18.552 18.2161 18.864 18.0641 19.192 17.8481C19.192 17.8481 18.792 18.5041 17.744 18.8001ZM13.736 16.6001C13.736 16.1121 14.096 15.7121 14.552 15.7121C15.008 15.7121 15.376 16.1121 15.368 16.6001C15.368 17.0881 15.008 17.4881 14.552 17.4881C14.104 17.4881 13.736 17.0881 13.736 16.6001ZM16.656 16.6001C16.656 16.1121 17.016 15.7121 17.472 15.7121C17.928 15.7121 18.288 16.1121 18.288 16.6001C18.288 17.0881 17.928 17.4881 17.472 17.4881C17.024 17.4881 16.656 17.0881 16.656 16.6001Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM9 10.648C9 9.736 9.736 9 10.64 9H21.36C22.264 9 23 9.736 23 10.648V25L21.28 23.48L20.312 22.584L19.288 21.632L19.712 23.112H10.64C9.736 23.112 9 22.376 9 21.464V10.648Z"
                fill="white"
              />
            </svg>
          </a>
          <a className="social" href="https://twitter.com/rydexperience">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM22.1 11.5C22.8 11.4 23.4 11.3 24 11C23.6 11.7 23 12.3 22.3 12.7C22.5 17.4 19.1 22.5 13 22.5C11.2 22.5 9.5 21.9 8 21C9.7 21.2 11.5 20.7 12.7 19.8C11.2 19.8 10 18.8 9.6 17.5C10.1 17.6 10.6 17.5 11.1 17.4C9.6 17 8.5 15.6 8.5 14.1C9 14.3 9.5 14.5 10 14.5C8.6 13.5 8.1 11.6 9 10.1C10.7 12.1 13.1 13.4 15.8 13.5C15.3 11.5 16.9 9.5 19 9.5C19.9 9.5 20.8 9.9 21.4 10.5C22.2 10.3 22.9 10.1 23.5 9.7C23.3 10.5 22.8 11.1 22.1 11.5Z"
                fill="white"
              />
            </svg>
          </a>
        </menu>
        <button
          type="button"
          className="primary-button"
          onClick={() => this.onConnectButtonClicked()}
        >
          {this.state.isWalletSync ? "Unsync" : "Sync"}
        </button>
      </section>
    );
  }
}

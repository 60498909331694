import Player from "./player/Player";
import Header from "./header/Header";
import ComingSoonPage from "./coming-soon-page/ComingSoonPage";
import "../../styles/app.css";

// TEST 2
export default function App() {
  const app = (
    <div className="main-container">
      <Header />
      <div className="line"></div>
      <Player />
    </div>
  );

  const comingSoon = (
    <div>
      <ComingSoonPage />
    </div>
  );

  const isComingSoon = true;

  return isComingSoon ? comingSoon : app;
}

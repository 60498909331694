import React from "react";
import "./player.css";
import Visualizer from "./Visualizer";
import TrackzList from "./TrackzList";
import Dealer from "./Dealer";

export default class PlayerComponent extends React.Component {
  render() {
    return (
      <div className="player">
        <div className="left-menu">
          <TrackzList />
          <Dealer />
        </div>
        <Visualizer />
      </div>
    );
  }
}

import ReactDOM from "react-dom";
import App from "./src/components/App";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const root = document.getElementById("root");
ReactDOM.render(App(), root);
declare global {
  interface String {
    capitalize: () => String;
  }
}

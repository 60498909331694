import React from "react";
import * as Tone from "tone";
import "./player.css";
import scheduler from "../../services/scheduler";

enum TrackzStates {
  LOADING = "Loading",
  READY = "Ready",
  WAITING = "Waiting",
  PLAYING = "Playing",
}

interface TrackzState {
  currentState: TrackzStates;
  currentColor: string;
}

interface TrackzProps {
  trackPath: string;
  onStart?: (string) => void;
  onStop?: (string) => void;
}

export default class Trackz extends React.Component<TrackzProps, TrackzState> {
  private player: Tone.Player;

  stateColors = {
    [TrackzStates.LOADING]: "#ff0000",
    [TrackzStates.READY]: "#56EBFF",
    [TrackzStates.PLAYING]: "#3EE778",
    [TrackzStates.WAITING]: "#FFF731",
  };

  state = {
    currentState: TrackzStates.LOADING,
    currentColor: this.stateColors[TrackzStates.LOADING],
  };

  constructor(props) {
    super(props);
    const { trackPath } = props;

    this.player = new Tone.Player(trackPath, () => {
      this.currentState = TrackzStates.READY;
      console.log(`Track "${trackPath}" has been loaded.`);
    }).toDestination();
    this.player.loop = true;
    this.player.autostart = false;
  }

  start() {
    scheduler.registerEvent(() => {
      console.log(`Start trackz '${this.props.trackPath}'.`);
      this.player.start();
      this.currentState = TrackzStates.PLAYING;
    });
    this.currentState = TrackzStates.WAITING;
  }

  stop() {
    this.player.stop();
    this.currentState = TrackzStates.READY;
  }

  get currentState(): TrackzStates {
    return this.state.currentState;
  }

  set currentState(state: TrackzStates) {
    this.setState({
      currentState: state,
      currentColor: this.stateColors[state],
    });
  }

  get isLoading() {
    return this.state.currentState === TrackzStates.LOADING;
  }

  toggle() {
    switch (this.state.currentState) {
      case TrackzStates.PLAYING:
        this.stop();
        break;
      case TrackzStates.READY:
        this.start();
        break;
    }
  }

  render() {
    const buttonStyle = {
      backgroundColor: this.state.currentColor,
    };
    return (
      <div className="trackz-button">
        <button
          onClick={() => this.toggle()}
          disabled={
            this.state.currentState === TrackzStates.LOADING ||
            this.state.currentState === TrackzStates.WAITING
          }
          style={buttonStyle}
        ></button>
        {/* {this.props.trackPath} */}
        {this.props.trackPath.split("/").pop().split(".")[0].capitalize()}
      </div>
    );
  }
}

const test2: TrackSet = {
  paths: [
    new URL("/assets/test2/conga.wav", import.meta.url).toString(),
    new URL("/assets/test2/hh.wav", import.meta.url).toString(),
    new URL("/assets/test2/kick.wav", import.meta.url).toString(),
    new URL("/assets/test2/snare.wav", import.meta.url).toString(),
  ],
  bpm: 90,
};

const theOne: TrackSet = {
  paths: [
    new URL("/assets/the-one/bass.wav", import.meta.url).toString(),
    new URL("/assets/the-one/drum.wav", import.meta.url).toString(),
    new URL("/assets/the-one/guitar1.wav", import.meta.url).toString(),
    new URL("/assets/the-one/guitar2.wav", import.meta.url).toString(),
  ],
  bpm: 78,
};

const trackz1: TrackSet = {
  paths: [
    new URL("/assets/trackz1-moine/drum1.wav", import.meta.url).toString(),
    new URL("/assets/trackz1-moine/drum2.wav", import.meta.url).toString(),
    new URL("/assets/trackz1-moine/synth1.wav", import.meta.url).toString(),
    new URL("/assets/trackz1-moine/synth2.wav", import.meta.url).toString(),
    new URL(
      "/assets/trackz1-moine/synthElectro.wav",
      import.meta.url
    ).toString(),
    new URL("/assets/trackz1-moine/guitare1.wav", import.meta.url).toString(),
    new URL("/assets/trackz1-moine/guitare2.wav", import.meta.url).toString(),
  ],
  bpm: 80,
};

interface TrackSet {
  paths: Array<string>;
  bpm: number;
}

export default trackz1;

import React from "react";
import "./player.css";

export default class Visualizer extends React.Component {
  render() {
    return (
      <div className="visualizer">
        <h2>What are TrackZ ?</h2>
        <p>
          Trackz are a limited collection of audio tracks that you can collect
          and play together in order to create your own song. All the Trackz
          were created by R.Y.D, a group of two musicians and generative
          artists, using electric guitars and digital instruments.
        </p>
      </div>
    );
  }
}
